exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-copy-js": () => import("./../../../src/pages/Join copy.js" /* webpackChunkName: "component---src-pages-join-copy-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/Join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-online-coaching-js": () => import("./../../../src/pages/OnlineCoaching.js" /* webpackChunkName: "component---src-pages-online-coaching-js" */),
  "component---src-pages-over-view-js": () => import("./../../../src/pages/OverView.js" /* webpackChunkName: "component---src-pages-over-view-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/Privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-rider-staff-js": () => import("./../../../src/pages/RiderStaff.js" /* webpackChunkName: "component---src-pages-rider-staff-js" */),
  "component---src-pages-sponsor-js": () => import("./../../../src/pages/Sponsor.js" /* webpackChunkName: "component---src-pages-sponsor-js" */),
  "component---src-templates-cms-detail-js": () => import("./../../../src/templates/cmsDetail.js" /* webpackChunkName: "component---src-templates-cms-detail-js" */),
  "component---src-templates-cms-list-js": () => import("./../../../src/templates/cmsList.js" /* webpackChunkName: "component---src-templates-cms-list-js" */)
}

